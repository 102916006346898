import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Heading wrap=flex",
        "componentName": "Heading",
        "wrap": "flex"
      }}>{`<Heading>An important title</Heading>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Heading" mdxType="Props" />
    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h2>
    <p><inlineCode parentName="p">{`default: colorBlackBase`}</inlineCode></p>
    <p>{`To change the text color.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Heading color="primaryBase">A colorized title</Heading>
<Heading color="colorPrimaryBase">A colorized title</Heading>
`}</code></pre>
    <h2 {...{
      "id": "decoration",
      "style": {
        "position": "relative"
      }
    }}>{`Decoration`}</h2>
    <p><inlineCode parentName="p">{`default: none`}</inlineCode></p>
    <p>{`Controls the text-decoration css attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Heading decoration="underline">A title with underline</Heading>
<Heading decoration="line-through">A title with line-through</Heading>
`}</code></pre>
    <h2 {...{
      "id": "fontfamily",
      "style": {
        "position": "relative"
      }
    }}>{`FontFamily`}</h2>
    <p><inlineCode parentName="p">{`default: system`}</inlineCode></p>
    <p>{`Font-families can be used to discern between multiple uses of a Heading.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Heading fontFamily="primary">A primary title</Heading>
<Heading fontFamily="secondary">A secondary title</Heading>
<Heading fontFamily="system">A system title</Heading>
`}</code></pre>
    <h2 {...{
      "id": "fontweight",
      "style": {
        "position": "relative"
      }
    }}>{`FontWeight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Controls the font-weight css attribute. Only 3 values are available. If a value
is missing, it will fallback on the more bold weight.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Heading fontWeight="inherit">inherit</Heading>
<Heading fontWeight="regular">regular</Heading>
<Heading fontWeight="medium">medium</Heading>
<Heading fontWeight="bold">bold</Heading>
`}</code></pre>
    <h2 {...{
      "id": "level",
      "style": {
        "position": "relative"
      }
    }}>{`Level`}</h2>
    <p><inlineCode parentName="p">{`default: 1`}</inlineCode></p>
    <p>{`To change the font-size and the html-tag.`}</p>
    <p>{`Note: Heading 1, 2, 3, 4, 5 and 6 have responsive behaviour.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Heading level={1}>Level 1</Heading>
<Heading level={2}>Level 2</Heading>
<Heading level={3}>Level 3</Heading>
<Heading level={4}>Level 4</Heading>
<Heading level={5}>Level 5</Heading>
<Heading level={6}>Level 6</Heading>
`}</code></pre>
    <h2 {...{
      "id": "textalign",
      "style": {
        "position": "relative"
      }
    }}>{`TextAlign`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Controls the css text-align attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Heading textAlign="inherit">inherit</Heading>
<Heading textAlign="left">left</Heading>
<Heading textAlign="right">right</Heading>
<Heading textAlign="center">center</Heading>
<Heading textAlign="justify">justify</Heading>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      